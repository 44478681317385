<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div class="header">
				<ion-buttons slot="end">
					<ion-menu-button color="dark"></ion-menu-button>
				</ion-buttons>
				<a href="/app/" router-direction="root" nav-transition="none"><img src="/assets/images/main-logo.png"></a>
			</div>
			<div id="container">
				<div class="page-header" v-html=article.title></div>
				<div class="post-content">
					<div class="content" v-html="article.content"></div>
					<div class="post-tags contact-us-box">
						<h3>Let us know what you think about our stories by commenting below each post. If you have a correction or feedback about how we can improve the app, please use the Contact button below.</h3>
						<a class="get-comments-button" href="mailto:editor@uinterview.com">Send us an email!</a>
					</div>

				</div>
				<div id="footer">
					<p>&copy;<span ref="copyYear"></span> uInterview<br>
					Entertainment news straight from the source.</p>
					<img src="/assets/images/main-logo.png" class="bottom-logo">
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonMenuButton, IonPage } from '@ionic/vue';
import axios from "axios";
import { jsonDataURL } from '@uinterview/uinterview'

export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonMenuButton,
		IonPage,
	},
	data() {
		return {
			componentKey: 0,
			article: []
		};
	},
	mounted() {
		const d = new Date
		this.$refs.copyYear.innerHTML = d.getFullYear()
		if (localStorage.appData) {
			const dateNow = Date.now()
			const jsonExpire = (JSON.parse(localStorage.appData).expires)*1000
			if(dateNow <= jsonExpire) {
				this.entriesList = JSON.parse(localStorage.appData)
				this.article = this.entriesList.pages[this.$route.params.id]
			} else {
				axios.get(jsonDataURL)
					.then(response => {
						this.entriesList = response.data
						localStorage.setItem('appData', JSON.stringify(response.data));
						this.entriesList = JSON.parse(localStorage.appData)
						this.article = this.entriesList.pages[this.$route.params.id]
					})
			}
		} else {
			axios.get(jsonDataURL)
				.then(response => {
					this.entriesList = response.data
					localStorage.setItem('appData', JSON.stringify(response.data));
					this.article = this.entriesList.pages[this.$route.params.id]
				})
		}
	},
	watch: {
		'$route' (to) {
			if(to.path.includes('/contentpage/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.pages[this.$route.params.id]
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.pages[this.$route.params.id]
								})
				}

			}
			this.$el.querySelector("#container").scrollIntoView({behavior: 'smooth'});
		}
	},
	methods: {
		sendEmail(){
			window['plugins'].socialsharing.shareViaEmail(
				'', // can contain HTML tags, but support on Android is rather limited:  http://stackoverflow.com/questions/15136480/how-to-send-html-content-with-image-through-android-default-email-client
				'uInterview App',
				['editor@uinterview.com'], // TO: must be null or an array
				null, // CC: must be null or an array
				null, // BCC: must be null or an array
			);
		}
	},
};
</script>

<style>
	@import '/assets/css/main.css';
</style>
